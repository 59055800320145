/* eslint-disable */

export class PaginationParams {
  page?: number = 1;
  pageSize?: number = 30;
  cursor?: string;
}

export class QueryParams extends PaginationParams {
  ordering?: string;
  search?: string;
  [key: string]: string | number | boolean | string[];
}
