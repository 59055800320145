import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import * as fromCoreContainer from './core/containers';
import * as coreGuards from './core/guards';
import * as fromGuards from './auth/guards';
import { GdvFeatureGuard } from '@aid/gdv/guards';
import { AccountingFeatureGuard } from '@aid/accounting/guards';
import { ReportsFeatureGuard } from '@aid/reports/guards';
import { PermissionsGuard } from './core/guards';
import { UserPermission } from '@aid/core/types/enums';
import { BillingGuard } from '@aid/billing/guards/billing.guard';

const routes: Routes = [
  {
    path: 'auth',
    canActivate: [fromGuards.NotLoggedInGuard],
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'no-organization',
    canActivate: [fromGuards.LoggedInGuard],
    component: fromCoreContainer.NoOrganizationComponent
  },
  {
    path: '',
    canActivate: [coreGuards.AuthGuard],
    children: [
      {
        path: ':organizationSlug',
        canActivate: [coreGuards.OrganizationGuard],
        children: [
          {
            path: '',
            component: fromCoreContainer.MainLayoutComponent,
            children: [
              {
                path: '',
                canActivate: [coreGuards.OrganizationSuspendedGuard],
                loadChildren: () =>
                  import('app/dashboard/dashboard.module').then(
                    m => m.DashboardModule
                  )
              },
              {
                path: 'not-found',
                component: fromCoreContainer.NotFoundPageComponent
              },
              {
                path: 'suspended',
                canActivate: [coreGuards.OrganizationNotSuspendedGuard],
                component: fromCoreContainer.SubscriptionSuspendedComponent
              },
              {
                path: 'management',
                canActivate: [
                  PermissionsGuard,
                  coreGuards.OrganizationSuspendedGuard
                ],
                data: {
                  permissions: [UserPermission.CAN_VIEW_USERS]
                },
                loadChildren: () =>
                  import('app/users-management/users-management.module').then(
                    m => m.UsersManagementModule
                  )
              },
              {
                path: 'damages',
                canActivate: [coreGuards.OrganizationSuspendedGuard],
                loadChildren: () =>
                  import('app/damages/damages.module').then(
                    m => m.DamagesModule
                  )
              },
              {
                path: 'reminders',
                canActivate: [coreGuards.OrganizationSuspendedGuard],
                loadChildren: () =>
                  import('app/reminders/reminders.module').then(
                    m => m.RemindersModule
                  )
              },
              {
                path: 'customers',
                canActivate: [coreGuards.OrganizationSuspendedGuard],
                loadChildren: () =>
                  import('app/customers/customers.module').then(
                    m => m.CustomersModule
                  )
              },
              {
                path: 'insurances',
                canActivate: [coreGuards.OrganizationSuspendedGuard],
                loadChildren: () =>
                  import('app/insurances/insurances.module').then(
                    m => m.InsurancesModule
                  )
              },
              {
                path: 'administration',
                canActivate: [
                  PermissionsGuard,
                  coreGuards.OrganizationSuspendedGuard
                ],
                data: {
                  permissions: [UserPermission.ADMINISTRATORS]
                },
                loadChildren: () =>
                  import('app/administration/administration.module').then(
                    m => m.AdministrationModule
                  )
              },
              {
                path: 'partnerships',
                canActivate: [
                  PermissionsGuard,
                  coreGuards.OrganizationSuspendedGuard
                ],
                data: {
                  permissions: [UserPermission.ADMINISTRATORS]
                },
                loadChildren: () =>
                  import('app/partnerships/partnerships.module').then(
                    m => m.PartnershipsModule
                  )
              },
              {
                path: 'profile',
                loadChildren: () =>
                  import('app/profile/profile.module').then(
                    m => m.ProfileModule
                  )
              },
              {
                path: 'gdv',
                canActivate: [
                  PermissionsGuard,
                  GdvFeatureGuard,
                  coreGuards.OrganizationSuspendedGuard
                ],
                data: {
                  permissions: [UserPermission.ADMINISTRATORS]
                },
                loadChildren: () =>
                  import('./gdv/gdv.module').then(m => m.GdvModule)
              },
              {
                path: 'accounting',
                canActivate: [
                  PermissionsGuard,
                  AccountingFeatureGuard,
                  coreGuards.OrganizationSuspendedGuard
                ],
                data: {
                  permissions: [UserPermission.ADMINISTRATORS]
                },
                loadChildren: () =>
                  import('./accounting/accounting.module').then(
                    m => m.AccountingModule
                  )
              },
              {
                path: 'reports',
                canActivate: [
                  PermissionsGuard,
                  ReportsFeatureGuard,
                  coreGuards.OrganizationSuspendedGuard
                ],
                data: {
                  permissions: [UserPermission.ADMINISTRATORS]
                },
                loadChildren: () =>
                  import('./reports/reports.module').then(m => m.ReportsModule)
              },
              {
                path: 'import',
                canActivate: [
                  PermissionsGuard,
                  coreGuards.OrganizationSuspendedGuard
                ],
                data: {
                  permissions: [UserPermission.ADMINISTRATORS]
                },
                loadChildren: () =>
                  import('./imports/imports.module').then(m => m.ImportsModule)
              },
              {
                path: 'billing',
                canActivate: [PermissionsGuard, BillingGuard],
                data: {
                  permissions: [UserPermission.ADMINISTRATORS]
                },
                loadChildren: () =>
                  import('./billing/billing.module').then(m => m.BillingModule)
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
