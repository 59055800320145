import { Encoding } from '@aid/profile/types/enums';

export class EmailConfiguration {
  id?: number;
  email: string;
  displayName: string;
  password?: string;
  smtpHost: string;
  smtpPort: number;
  imapHost: string;
  imapPort: number;
  encoding: Encoding;
  sentFolder: string;
  copyToSentFolder: boolean;
  bcc: string;
  useWebhook: boolean;
  webhookUrl: string;
}
